.info-container {
    text-align: left;
    margin-top: 3em;
    padding-bottom: 3em
}

.info-header {
    font-size: 2em;
}

.info-subheadder {
    font-size: 1.5em;
    margin-top: 2em;
}

.info-paragraph {
    font-size: 1.2em;
    line-height: 1.5em;
}