.grid {
    padding-top: 2em;
}

.article-blog {
    color: #39414D;
    text-align: left;
    padding: 2em 0;
}

.article-body-blog {
    max-width: 100%;
}

.social-blog {
    margin-bottom: 0.5em;
    text-align: center;
}

.header-blog {
    font-size: 2.5em;
    font-weight: bolder;
    line-height: 1em;
    color: black;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.subheader-blog {
    color: black;
    margin-bottom: 1em;
    margin-top: 0;
}

.img-blog {
    width: 100%;
}

.paragraph-blog {
    font-size: 1.2em;
    line-height: 1.5em;
}

.calculator {
    margin-bottom: 1em;
}